import { render, staticRenderFns } from "./AllPayrolls.vue?vue&type=template&id=b258d280&scoped=true"
import script from "./AllPayrolls.vue?vue&type=script&lang=js"
export * from "./AllPayrolls.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b258d280",
  null
  
)

export default component.exports